import { pageData } from '@utils/subpages-data/ksiegowosc';
import React from 'react';

import Subpage from './_subpage';

const Bookkeeping = () => {
  return <Subpage data={pageData} />;
};

export default Bookkeeping;
