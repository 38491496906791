import przemek from '@images/workers/przemek.webp';
import { phoneNumberPrzemek } from '@utils/variables';

const nbsp = '\u00A0';

export const pageData = {
  id: 1,
  title: `Księgowość i kadry`,
  slug: `ksiegowosc-i-kadry`,
  worker: {
    employee: `Przemysław Pruszyński`,
    employeePhone: `${phoneNumberPrzemek}`,
    employeePhoto: przemek,
  },
  description: {
    content: `Zapewniamy kompleksowe i profesjonalne usługi rachunkowe i księgowe dla wszystkich podmiotów gospodarczych, bez względu na formę prawną i${nbsp}profil działalności.`,
    list: [
      `Oferujemy:`,
      `prowadzenie ksiąg handlowych`,
      `prowadzenie uproszczonych form księgowości`,
      `pełną obsługę kadrowo - płacową pracowników`,
      `wsparcie HR w postaci rekrutacji i pozyskiwania pracowników - posiadamy wpis do Krajowego Rejestru Agencji Zatrudnienia`,
      `szkolenia i rozwój pracowników`,
    ],
  },
};

export default {};
